















// Custom Components
import DateRangeSelector from "@/components/forms/reporting-analytics/DateRangeSelector.vue";
import SupplierHeaderFieldRecogChart from "@/components/reporting-analytics/charts/SupplierHeaderFieldRecog.vue";

// Store
import RecognitionAnalyticsStore from "@/store/reporting-analytics/recognition-analytics.store";

// Third Party Libs
import { getModule } from "vuex-module-decorators";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    "date-range-selector": DateRangeSelector,
    "supplier-header-field-recog-chart": SupplierHeaderFieldRecogChart
  }
})
export default class RecognitionAnalytics extends Vue {
  // store module
  private readonly analyticsStore: RecognitionAnalyticsStore = getModule(
    RecognitionAnalyticsStore,
    this.$store
  );

  // computed properties
  private get dateRange() {
    return this.analyticsStore.getDateRange;
  }
  private get searchButtonClicked() {
    return this.analyticsStore.getSearchButtonClicked;
  }

  // methods
  handleDateRangeChange(selection: { startDate: string; endDate: string }) {
    this.analyticsStore.updateDateRange(selection);
    this.analyticsStore.clickAnalyticsSearchButton();
  }
}
