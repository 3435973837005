

















































































































import VueApexCharts from "vue-apexcharts";
import { Vue, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
  CategoryChartData,
  CategoryChartSeries
} from "@/models/reporting-analytics/category-chart-data";
import { InvoiceListData } from "@/models/reporting-analytics/invoice-list-data";
import { reportingAnalyticsService } from "@/services/reporting-analytics.service";
import RecognitionAnalyticsStore from "@/store/reporting-analytics/recognition-analytics.store";
import AnalyticsInvoiceItem from "@/components/reporting-analytics/data/AnalyticsInvoiceItem.vue";
import InvoiceListTable from "@/components/reporting-analytics/data/InvoiceListTable.vue";
import VueSlider from "vue-slider-component";
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";
import "@/design/third-party-libs/_vue-slider-theme.scss";

@Component({
  components: {
    apexchart: VueApexCharts,
    "vue-slider": VueSlider,
    "invoice-list-table": InvoiceListTable,
    "analytics-invoice-item": AnalyticsInvoiceItem
  }
})
export default class SupplierHeaderFieldRecogChart extends Vue {
  // store module
  private readonly analyticsStore: RecognitionAnalyticsStore = getModule(
    RecognitionAnalyticsStore,
    this.$store
  );

  // reactive class properties
  private isFetchingItem = false;
  private loadingInvoiceList = false;
  private loadingSupplierRecogData = false;
  private loadingFieldRecogData = false;
  private selectedField = "";
  private accuracyRangeValue = [0, 100];
  private selectedSupplier = "";
  private selectedSupplierNameRange = ["A", "Z"];
  private supplierNameRange = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];
  private series: CategoryChartSeries[] = [];
  private supplierRecogSeries: CategoryChartSeries[] = [];
  private supplierRecogData: CategoryChartData[] = [];
  private invoiceList: InvoiceListData[] = [];
  private chartOptions = {
    chart: {
      id: "allSuppliersAverageRecogByField",
      fontFamily: "'Lato', sans-serif",
      toolbar: {
        export: {
          csv: {
            filename: `header-field-recognition-${this.dateRange.startDate}-to-${this.dateRange.endDate}`,
            columnDelimiter: ",",
            headerCategory: "Field"
          }
        }
      },
      events: {
        dataPointSelection: async (e: any, chart: any, opts: any) => {
          const recogByField = document.querySelector(
            "#recog-by-field-all-suppliers"
          );
          const supplierRecogForField = document.querySelector(
            "#supplier-recog-for-selected-field"
          );
          if (opts.selectedDataPoints[0]) {
            if (
              opts.selectedDataPoints[0].length === 1 &&
              supplierRecogForField != null &&
              supplierRecogForField.classList.contains("active")
            ) {
              this.updateSupplierRecogChart(chart, "supplierRecogForField");
            } else if (
              opts.selectedDataPoints[0].length === 1 &&
              supplierRecogForField != null &&
              recogByField != null
            ) {
              recogByField.classList.add("supplier-recog-chart-activated");
              supplierRecogForField.classList.add("active");
              this.updateSupplierRecogChart(chart, "supplierRecogForField");
            }
          }
          if (
            recogByField != null &&
            supplierRecogForField != null &&
            opts.selectedDataPoints[0]
          ) {
            if (opts.selectedDataPoints[0].length === 0) {
              recogByField.classList.remove("header-recog-chart-activated");
              supplierRecogForField.classList.remove("active");
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: true
    },
    states: {
      normal: {
        filter: {
          type: "desaturate"
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "darken",
          value: 0.25
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true
      }
    },
    yaxis: {
      title: {
        text: "Field"
      },
      min: 0,
      max: 100
    },
    xaxis: {
      type: "category",
      title: {
        text: "Recognition rate (%)"
      }
    },
    title: {
      text: "Recognition Rate by Field",
      align: "left"
    },
    subtitle: {
      text: "Average across all suppliers for selected date range",
      align: "left"
    },
    colors: [
      "#07445b",
      "#49547b",
      "#77677a",
      "#bf9856",
      "#e28b44",
      "#d46b4f",
      "#bb5055",
      "#9e3a58",
      "#7c285a"
    ],
    legend: {
      show: false
    },
    tooltip: {
      y: {
        formatter: function(value: any) {
          if (value) {
            return value.toString().concat("%");
          }
        }
      }
    },
    noData: {
      text: "No data for selected options",
      align: "center"
    }
  };

  private supplierRecogChartOptions = {
    chart: {
      id: "supplierRecogForField",
      type: "bar",
      fontFamily: "'Lato', sans-serif",
      toolbar: {
        export: {
          csv: {
            filename: `supplier-recog-${this.selectedField}-${this.dateRange.startDate}-to-${this.dateRange.endDate}`,
            columnDelimiter: ",",
            headerCategory: "Supplier ID"
          }
        }
      },
      events: {
        dataPointSelection: async (e: any, chart: any, opts: any) => {
          const supplierRecogForField = document.querySelector(
            "#supplier-recog-for-selected-field"
          );
          if (opts.selectedDataPoints[0]) {
            if (
              opts.selectedDataPoints[0].length === 1 &&
              supplierRecogForField != null &&
              supplierRecogForField.classList.contains("active")
            ) {
              await this.updateInvoiceList(chart);
            }
          }
          if (supplierRecogForField != null && opts.selectedDataPoints[0]) {
            if (opts.selectedDataPoints[0].length === 0) {
              supplierRecogForField.classList.remove("active");
            }
          }
        },
        click: async (e: any, chart: any, opts: any) => {
          await this.updateInvoiceListForClickOnZeroValue(chart, opts);
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: "Recognition Rate by Supplier by Field",
      align: "left"
    },
    subtitle: {
      text: "Selected Field",
      align: "left"
    },
    yaxis: {
      title: {
        text: "Recognition rate (%)"
      },
      min: 0,
      max: 100
    },
    xaxis: {
      type: "category",
      title: {
        text: "Supplier ID"
      },
      labels: {
        formatter: (value: any) => {
          if (value.length) {
            const supplierIdIndex = value.lastIndexOf("/");
            const supplierId = value.substring(supplierIdIndex + 1);
            return supplierId;
          }
        }
      }
    },
    colors: ["#07445b"],
    legend: {
      show: false
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: function(value: any) {
          if (value.length) {
            const supplierIdIndex = value.lastIndexOf("/");
            return value
              .substring(0, supplierIdIndex - 1)
              .concat(` (${value.substring(supplierIdIndex + 1)})`);
          }
        }
      },
      y: {
        formatter: function(value: any) {
          if (value) {
            return value.toString().concat("%");
          }
        }
      }
    },
    noData: {
      text: "No data for selected options",
      align: "center"
    }
  };

  // computed properties
  get recogByHeaderFieldData() {
    return this.analyticsStore.getRecogByHeaderFieldData;
  }
  get dateRange() {
    return this.analyticsStore.getDateRange;
  }
  get searchButtonClicked() {
    return this.analyticsStore.getSearchButtonClicked;
  }
  get selectedInvoice() {
    return this.analyticsStore.getSelectedInvoice;
  }
  get selectedInvoicePdfLocation() {
    return this.analyticsStore.getSelectedInvoicePdfLocation;
  }

  // lifecycle methods
  async created() {
    if (Object.keys(this.recogByHeaderFieldData).length === 0) {
      await this.fetchNewChartData();
    }
    this.updateChart();
  }

  // methods
  @Catch((error: any, context: any) => {
    context.loadingFieldRecogData = false;
    context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async fetchNewChartData() {
    // clear field, supplier, and invoice selections
    this.selectedField = "";
    this.selectedSupplier = "";
    this.analyticsStore.updateSelectedInvoice(null);

    // hide supplier recognition chart
    const supplierRecogForField = document.querySelector(
      "#supplier-recog-for-selected-field"
    );
    if (supplierRecogForField != null) {
      supplierRecogForField.classList.remove("active");
    }

    // load new chart data
    this.loadingFieldRecogData = true;
    const chartData: {
      [key: string]: CategoryChartData[];
    } = await reportingAnalyticsService.loadRecogbyFieldAsync(
      this.dateRange.startDate,
      this.dateRange.endDate,
      true // include VS
    );
    this.analyticsStore.updateRecogByHeaderFieldData(chartData.recogRate);

    this.loadingFieldRecogData = false;
  }

  private handleFetchImage(fetching: boolean) {
    this.isFetchingItem = fetching;
  }

  updateChart() {
    this.series = [
      {
        name: "Recognition rate",
        data: this.recogByHeaderFieldData
      }
    ];
  }

  // eslint-disable @typescript-eslint/no-unused-vars
  @Catch((error: any, context: any) => {
    context.loadingFieldRecogData = false;
    context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async updateSupplierRecogChart(sourceChart: any, destChartIDToUpdate: any) {
    // clear supplier and invoice selections
    this.analyticsStore.updateSelectedInvoice(null);
    this.selectedSupplier = "";

    if (sourceChart.w.globals.selectedDataPoints[0]) {
      // reset defaults
      this.loadingSupplierRecogData = true;
      this.accuracyRangeValue = [0, 100];
      this.selectedSupplierNameRange = ["A", "Z"];

      // load chart data for selected field
      this.selectedField =
        sourceChart.w.globals.labels[
          sourceChart.w.globals.selectedDataPoints[0][0]
        ];
      const supplierRecogData = await reportingAnalyticsService.loadRecogBySupplierForFieldAsync(
        this.dateRange.startDate,
        this.dateRange.endDate,
        this.selectedField
      );
      this.supplierRecogData = await supplierRecogData;

      // update drill-down chart
      this.supplierRecogSeries = [
        {
          name: "Recognition rate",
          data: await supplierRecogData
        }
      ];
      this.supplierRecogChartOptions = {
        ...this.supplierRecogChartOptions,
        subtitle: {
          text: `${this.selectedField} for selected date range`,
          align: "left"
        }
      };

      this.loadingSupplierRecogData = false;
    }
  }

  // used to filter data points displayed in the drill-down chart
  updateSupplierRecogDataDisplayed() {
    // deselect any selected supplier or invoice when user filters suppliers
    this.selectedSupplier = "";
    this.analyticsStore.updateSelectedInvoice(null);

    // find suppliers whose names start with a letter in the selected range
    const supplierNameMatches: CategoryChartData[] = [];
    const startLetterIndex = this.supplierNameRange.indexOf(
      this.selectedSupplierNameRange[0]
    );
    const stopLetterIndex = this.supplierNameRange.indexOf(
      this.selectedSupplierNameRange[1]
    );
    for (let i = startLetterIndex; i <= stopLetterIndex; i++) {
      this.supplierRecogData.forEach((obj: CategoryChartData) => {
        if (obj.x.startsWith(this.supplierNameRange[i])) {
          supplierNameMatches.push(obj);
        }
      });
    }

    // filter data to suppliers in name and accuracy range selected
    const filteredSupplierData = this.supplierRecogData.filter(
      (obj: CategoryChartData) =>
        obj.y >= this.accuracyRangeValue[0] &&
        obj.y <= this.accuracyRangeValue[1] &&
        supplierNameMatches.includes(obj)
    );

    // update chart with filtered data
    this.supplierRecogSeries = [
      {
        name: "Recognition rate",
        data: filteredSupplierData
      }
    ];
  }

  @Catch((error: any, context: any) => {
    context.loadingInvoiceList = false;
    context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async updateInvoiceList(sourceChart: any) {
    this.analyticsStore.updateSelectedInvoice(null);
    if (sourceChart.w.globals.selectedDataPoints[0]) {
      this.loadingInvoiceList = true;
      this.selectedSupplier =
        sourceChart.w.globals.labels[
          sourceChart.w.globals.selectedDataPoints[0][0]
        ];

      // identify which supplier ID was selected
      const supplierIdIndex = this.selectedSupplier.lastIndexOf("/");
      const selectedSupplierId = this.selectedSupplier.substring(
        supplierIdIndex + 1
      );
      this.invoiceList = await reportingAnalyticsService.loadInvoiceListAsync(
        this.dateRange.startDate,
        this.dateRange.endDate,
        "entered", // only include invoices entered in WD
        selectedSupplierId // return only invoices for selected supplier
      );

      this.loadingInvoiceList = false;
    }
  }

  // eslint-disable @typescript-eslint/no-unused-vars
  @Catch((error: any, context: any) => {
    context.loadingInvoiceList = false;
    context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async updateInvoiceListForClickOnZeroValue(sourceChart: any, options: any) {
    // unselected any selected invoice
    this.analyticsStore.updateSelectedInvoice(null);
    this.loadingInvoiceList = true;

    // find clicked data point's index in chart series data
    const selectedDataPoint = options.dataPointIndex;

    // obtain name and supplier ID from x-axis value of selectedDataPoint
    if (this.supplierRecogSeries[0].data[selectedDataPoint]) {
      this.selectedSupplier = this.supplierRecogSeries[0].data[
        selectedDataPoint
      ].x;

      // isolate supplier ID
      const supplierIdIndex = this.selectedSupplier.lastIndexOf("/");
      const selectedSupplierId = this.selectedSupplier.substring(
        supplierIdIndex + 1
      );

      // update list of invoices displayed
      this.invoiceList = await reportingAnalyticsService.loadInvoiceListAsync(
        this.dateRange.startDate,
        this.dateRange.endDate,
        "entered", // only include invoices entered in WD
        selectedSupplierId // return only invoices for selected supplier
      );
    }
    this.loadingInvoiceList = false;
  }

  formatSupplier(supplierName: string): string {
    if (supplierName.lastIndexOf("/") > 0) {
      return (
        supplierName.substring(0, supplierName.lastIndexOf("/")) +
        "(" +
        supplierName.substring(supplierName.lastIndexOf("/") + 1) +
        ")"
      );
    }
    return "";
  }

  // watchers
  @Watch("searchButtonClicked")
  async updateSearch() {
    await this.fetchNewChartData();
    this.updateChart();
    this.$emit("chartLoaded");
  }
}
